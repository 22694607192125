import clsx from "clsx";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const SaleTotalPaid = ({
  payments,
  total = 0,
  coins = 0,
}: {
  payments: any[];
  total: number;
  coins: number;
  isQuotation?: boolean;
}) => {
  const [t] = useTranslation("global");
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    let newTotal = 0;
    for (let i = 0; i < (payments ?? []).length; i++) {
      newTotal += Number(get(payments[i], "amount", 0));
    }
    if (newTotal !== totalAmount) {
      setTotalAmount(Number(newTotal.toFixed(2)));
    }
  }, [payments, total, coins]);

  return (
    <>
      <div
        className={clsx({
          ["successContainer"]: totalAmount === total - coins,
          ["warningContainer"]: totalAmount !== total - coins,
        })}
      >
        {totalAmount === total - coins ? (
          t("PAYMENT_SUM_CORRECT")
        ) : (
          <>
            {t("PAYMENT_SUM_INCORRECT")}
            <br />
            <span className="bold mdFont">
              {t("PENDING")}
              {": "}
            </span>
            <span>{total - coins - totalAmount}</span>
          </>
        )}
      </div>
    </>
  );
};
