import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import { getProductByIds, searchProducts } from "@/http";
import { useTranslation } from "react-i18next";
import { singleSelectStyles } from "@/constants/input";
import useSWR from "swr";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash-es";
import { useAuth } from "@/store/auth";
import { ProductSelectOption } from "@/components/offers/_form/ProductSelectOption";

export const SingleSelectionProduct = ({
  name,
  disabled = false,
  required = false,
  value = "",
}: {
  name: string;
  required: boolean;
  disabled: boolean;
  value: string;
}) => {
  const [t] = useTranslation("global");
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const {
    data: products,
    isValidating,
    mutate,
  } = useSWR(value ? [[value], "products-supplier"] : null, getProductByIds);

  const defaultValue = get(products, "[0]", { id: "", name: "" });

  useEffect(() => {
    if (value) {
      mutate();
    }
  }, [value, disabled, mutate]);

  return (
    <>
      {!isValidating && (
        <ProductInput
          value={
            defaultValue?.id
              ? {
                  value: defaultValue.id,
                  label: defaultValue.name,
                  ...defaultValue,
                }
              : null
          }
          disabled={disabled}
          onValueChange={(selected: any) => {
            setValue(name, selected?.value ?? "");
          }}
        />
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? t("LOGIN.FIELD_REQUIRED") : undefined,
        }}
        render={() => <></>}
      />
      {!!get(errors, name) && (
        <span className="redText mdFont ml2">
          {get(errors as any, name).message}
        </span>
      )}
    </>
  );
};

type TProductInput = {
  value: any;
  disabled?: boolean;
  onValueChange: (value: any) => void;
};

const ProductInput = ({ value, disabled, onValueChange }: TProductInput) => {
  const { user } = useAuth();
  const [t] = useTranslation("global");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [options, setOptions] = useState<any[]>([]);

  const fetchOptions = async (input: string) => {
    try {
      const response = await searchProducts({
        data: { text: input, supplierId: user.id },
        params: {
          sort: "name,asc",
          page: 0,
          size: 10,
        },
      });
      const optionsData = response?.content?.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      }));
      setOptions(optionsData);
    } catch (_) {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    onValueChange?.(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (inputValue) {
      debouncedFetchOptions(inputValue);
    } else {
      debouncedFetchOptions("");
    }
  }, [inputValue, debouncedFetchOptions]);

  return (
    <>
      <p>{t("PRODUCT")}</p>
      <Select
        options={options}
        value={selectedOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={t("SELECT_OPTION")}
        styles={singleSelectStyles}
        noOptionsMessage={() => t("NO_OPTIONS")}
        formatOptionLabel={(product, { context }) =>
          context === "menu" ? (
            <ProductSelectOption product={product} />
          ) : (
            product.name
          )
        }
      />
    </>
  );
};
