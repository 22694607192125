import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./styles/_main.scss";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { Login } from "@/pages/Login/Login";
import PrivateRoute from "@/components/app/PrivateRoute";
import PublicRoute from "@/components/app/PublicRoute";
import InnerContent from "@/components/app/InnerContent/InnerContent";
import Orders from "@/pages/Orders/Orders";
import PageOrderDetail from "@/pages/Orders/OrderDetail";
import EmailLinkLogin from "@/pages/EmailLinkLogin";
import { useAuth } from "@/store/auth";
import { initializeDependencies } from "@/config/boot";
import Account from "@/pages/SupplierProfile";
import { SubscriptionPage } from "./pages/Subscription/SubscriptionPage";
import HelpPage from "@/pages/Help/Help";
import NotFoundPage from "@/pages/NotFound";
import ProductsPage from "@/pages/Products/ProductsPage";
import ClientList from "@/pages/Clients/ClientList";
import ClientShow from "@/pages/Clients/ClientShow";
import { PurchasesListPage } from "@/pages/Purchases/PurchasesList";
import { PurchasesCreatePage } from "@/pages/Purchases/PurchasesCreate";
import { PurchasesShowPage } from "@/pages/Purchases/PurchasesShow";
import { TieredDiscountsPage } from "@/pages/TieredDiscounts/TieredDiscounts";
import { CreateProductGroupPage } from "./pages/Products/CreateProductGroup";
import { OffersPage } from "./pages/Offers/Offers";
import { CreateOfferPage } from "./pages/Offers/CreateOffer";
import { ShowOfferPage } from "./pages/Offers/ShowOffer";
import DashboardPage from "./pages/Dashboard";
import TicketsList from "./pages/Tickets/TicketsList";
import { SearchProductsPage } from "./pages/Products/SearchProducts";
import { RelateProductPage } from "./pages/Products/RelateProduct";
import IntegrationsPage from "./pages/Integrations/IntegrationsPage";
import { VariantDetailPage } from "./pages/Products/VariantDetailPage";
import { PaymentsListPage } from "./pages/Payments/PaymentsListPage";
import { PaymentDetailPage } from "./pages/Payments/PaymentsDetailPage";
import { CommercialsList } from "./pages/Commercials/CommercialsList";
import { Restricted } from "./pages/Restricted/Restricted";
import { RoutePermission } from "./components/app/RoutePermission";
import { InvoicePage } from "./pages/Invoices/InvoicePage";
import { InvoiceOrderPage } from "./pages/Invoices/InvoiceOrderPage";
import { PurchasesOrderCreatePage } from "./pages/Purchases/PurchaseOrder";
import { ProductBulksPage } from "./pages/ProductBulks/ProductBulks";
import SegmentsPage from "./pages/Segments/SegmentsPage";
import { QuotesListPage } from "./pages/Quotes/QuotesList";
import { QuoteCreationPage } from "./pages/Quotes/QuoteCreation";
import { AcquisitionsListPage } from "./pages/Acquisitions/AcquisitionsList";
import { SignupPage } from "./pages/Signup/SignupPage";
import { PowerBIReport } from "./pages/PowerBIReport";
import CreditsPage from "@/pages/Credits/CreditsPage";
import TransactionsPage from "./pages/Credits/TransactionsPage";
import IntegrationsCallbackPage from "./pages/Integrations/IntegrationsCallbackPage";
import { CommercialTracking } from "./pages/Commercials/CommercialsTracking";
import { CreditExtractPage } from "./pages/Credits/CreditExtractPage";
import { CreateProductVariantPage } from "./pages/Products/CreateProductVariant";
import { CustomizePage } from "./pages/Customize/CustomizePage";
import { CoinsVaultPage } from "./pages/Profile/CoinsVault";
import { SupplierEventsPage } from "./pages/Clients/SupplierEvents";
import { EditProductPage } from "./pages/Products/EditProduct";

function App() {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    initializeDependencies()
      .then(() => setReady(true))
      .catch(() => setError("Error de configuración remota."));
  }, []);

  useEffect(() => {
    if (user?.id) {
      Sentry.onLoad(() => {
        Sentry.setUser({
          id: user?.id ?? "",
          email: user?.email ?? "",
        });
      });
    } else {
      Sentry.onLoad(() => {
        Sentry.setUser(null);
      });
    }
  }, [user]);

  if (error) return <>{error}</>;
  if (!ready) return <Spinner />;

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<InnerContent />}>
              <Route path="/" element={<Navigate replace to="purchases" />} />
              <Route
                path="/account"
                element={
                  <RoutePermission
                    element={<Account />}
                    permissions={["route.account.show"]}
                  />
                }
              />
              <Route
                path="/subscription"
                element={
                  <RoutePermission
                    element={<SubscriptionPage />}
                    permissions={["route.subscription.show"]}
                  />
                }
              />
              <Route path="/customize" element={<CustomizePage />} />
              <Route path="/metrics" element={<PowerBIReport />} />
              <Route
                path="/dashboard"
                element={
                  <RoutePermission
                    element={<DashboardPage />}
                    permissions={["route.dashboard.show"]}
                  />
                }
              />
              <Route
                path="/commercial-tracking"
                element={
                  <RoutePermission
                    element={<CommercialTracking />}
                    permissions={["route.commercial-tracking.show"]}
                  />
                }
              />
              <Route
                path="/help"
                element={
                  <RoutePermission
                    element={<HelpPage />}
                    permissions={["route.help.show"]}
                  />
                }
              />
              <Route
                path="/orders"
                element={
                  <RoutePermission
                    element={<Orders />}
                    permissions={["route.orders.list"]}
                  />
                }
              />
              <Route
                path="/orders/:id"
                element={
                  <RoutePermission
                    element={<PageOrderDetail />}
                    permissions={["route.orders.show"]}
                  />
                }
              />
              <Route
                path="/orders/:id/invoice"
                element={
                  <RoutePermission
                    element={<InvoiceOrderPage />}
                    permissions={["route.orders.show"]}
                  />
                }
              />
              <Route
                path="/product-bulks"
                element={
                  <RoutePermission
                    element={<ProductBulksPage />}
                    permissions={["route.products.list"]}
                  />
                }
              />
              <Route
                path="/products"
                element={
                  <RoutePermission
                    element={<ProductsPage />}
                    permissions={["route.products.list"]}
                  />
                }
              />
              <Route
                path="/products/search"
                element={
                  <RoutePermission
                    element={<SearchProductsPage />}
                    permissions={["route.products.search"]}
                  />
                }
              />
              <Route
                path="/products/create/new"
                element={
                  <RoutePermission
                    element={<CreateProductVariantPage />}
                    permissions={["route.products.create"]}
                  />
                }
              />
              <Route
                path="/products/create/group"
                element={
                  <RoutePermission
                    element={<CreateProductGroupPage />}
                    permissions={["route.products.create"]}
                  />
                }
              />
              <Route
                path="/products/create/relate/:id"
                element={
                  <RoutePermission
                    element={<RelateProductPage />}
                    permissions={["route.products.relate"]}
                  />
                }
              />
              <Route
                path="/products/:id"
                element={
                  <RoutePermission
                    element={<VariantDetailPage />}
                    permissions={["route.products.detail"]}
                  />
                }
              />
              <Route
                path="/products/:id/edit"
                element={
                  <RoutePermission
                    element={<EditProductPage />}
                    permissions={["action.product.edit"]}
                  />
                }
              />
              <Route
                path="/clients"
                element={
                  <RoutePermission
                    element={<ClientList />}
                    permissions={["route.clients.list"]}
                  />
                }
              />
              <Route
                path="/credits"
                element={
                  <RoutePermission
                    element={<CreditsPage />}
                    permissions={["route.credits.list"]}
                  />
                }
              />
              <Route
                path="/credits/:id/transactions"
                element={
                  <RoutePermission
                    element={<TransactionsPage />}
                    permissions={["route.transactions.list"]}
                  />
                }
              />
              <Route
                path="/credits/:id/transactions/extract"
                element={
                  <RoutePermission
                    element={<CreditExtractPage />}
                    permissions={["route.transactions.list"]}
                  />
                }
              />
              <Route
                path="/segments"
                element={
                  <RoutePermission
                    element={<SegmentsPage />}
                    permissions={["route.clients.list"]}
                  />
                }
              />
              <Route
                path="/offers"
                element={
                  <RoutePermission
                    element={<OffersPage />}
                    permissions={["route.offers.list"]}
                  />
                }
              />
              <Route
                path="/offers/create"
                element={
                  <RoutePermission
                    element={<CreateOfferPage />}
                    permissions={["route.offers.create"]}
                  />
                }
              />
              <Route
                path="/offers/:id/show"
                element={
                  <RoutePermission
                    element={<ShowOfferPage />}
                    permissions={["route.offers.show"]}
                  />
                }
              />
              <Route
                path="/clients/:id/show"
                element={
                  <RoutePermission
                    element={<ClientShow />}
                    permissions={["route.clients.show"]}
                  />
                }
              />
              <Route
                path="/clients/events"
                element={
                  <RoutePermission
                    element={<SupplierEventsPage />}
                    permissions={["route.clients.show"]}
                  />
                }
              />

              <Route
                path="/clients/:id/coins"
                element={
                  <RoutePermission
                    element={<CoinsVaultPage />}
                    permissions={["route.clients.show"]}
                  />
                }
              />
              <Route
                path="/tiered-discounts"
                element={
                  <RoutePermission
                    element={<TieredDiscountsPage />}
                    permissions={["route.tiereddiscounts.list"]}
                  />
                }
              />
              <Route
                path="/acquisitions"
                element={
                  <RoutePermission
                    element={<AcquisitionsListPage />}
                    permissions={["route.purchases.list"]}
                  />
                }
              />
              <Route
                path="/acquisitions/create"
                element={
                  <RoutePermission
                    element={<PurchasesOrderCreatePage />}
                    permissions={["route.purchases.create"]}
                  />
                }
              />
              <Route
                path="/acquisitions/:id/show"
                element={
                  <RoutePermission
                    element={<PurchasesShowPage />}
                    permissions={["route.purchases.show"]}
                  />
                }
              />
              <Route
                path="/quotes"
                element={
                  <RoutePermission
                    element={<QuotesListPage />}
                    permissions={["route.purchases.list"]}
                  />
                }
              />
              <Route
                path="/quotes/create"
                element={
                  <RoutePermission
                    element={<QuoteCreationPage />}
                    permissions={["route.purchases.create"]}
                  />
                }
              />
              <Route
                path="/quotes/:id/show"
                element={
                  <RoutePermission
                    element={<PurchasesShowPage />}
                    permissions={["route.purchases.show"]}
                  />
                }
              />
              <Route
                path="/purchases"
                element={
                  <RoutePermission
                    element={<PurchasesListPage />}
                    permissions={["route.purchases.list"]}
                  />
                }
              />
              <Route
                path="/purchases/create"
                element={
                  <RoutePermission
                    element={<PurchasesCreatePage />}
                    permissions={["route.purchases.create"]}
                  />
                }
              />
              <Route
                path="/purchases/:id/show"
                element={
                  <RoutePermission
                    element={<PurchasesShowPage />}
                    permissions={["route.purchases.show"]}
                  />
                }
              />
              <Route
                path="/purchases/:id/invoice"
                element={
                  <RoutePermission
                    element={<InvoicePage />}
                    permissions={["route.purchases.show"]}
                  />
                }
              />
              <Route
                path="/tickets"
                element={
                  <RoutePermission
                    element={<TicketsList />}
                    permissions={["route.tickets.list"]}
                  />
                }
              />
              <Route
                path="/integrations"
                element={
                  <RoutePermission
                    element={<IntegrationsPage />}
                    permissions={["route.integrations"]}
                  />
                }
              />
              <Route
                path="/integrations/:id/callback"
                element={<IntegrationsCallbackPage />}
              />
              <Route
                path="/payments"
                element={
                  <RoutePermission
                    element={<PaymentsListPage />}
                    permissions={["route.paymnts.list"]}
                  />
                }
              />
              <Route
                path="/payments/:id"
                element={
                  <RoutePermission
                    element={<PaymentDetailPage />}
                    permissions={["route.payments.show"]}
                  />
                }
              />
              <Route
                path="/commercials"
                element={
                  <RoutePermission
                    element={<CommercialsList />}
                    permissions={["route.commercials.list"]}
                  />
                }
              />
              <Route path="/restricted" element={<Restricted />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Route>
          <Route path="/" element={<PublicRoute />}>
            <Route path="/link-login" element={<EmailLinkLogin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignupPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
