import { PreviewFile } from "@/components/products/ProductsForm/ProductImage";
import { Card } from "@/components/shared/Card/Card";
import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import { MdOutlineDelete } from "react-icons/md";
import { SalePaymentsForm } from "./SalePaymentsForm";
import styles from "./styles.module.scss";

export const SalePaymentDetail = ({
  payment,
  onDelete,
  payments,
  paymentMethods,
  total,
  onEdit,
  coins = 0,
}: {
  payment: any;
  payments: any[];
  paymentMethods: any[];
  total: number;
  onDelete: (...args: any) => Promise<void> | void;
  onEdit: (payment: any) => Promise<void> | void;
  coins: number;
}) => {
  const [t] = useTranslation("global");

  return (
    <Card className="pwmd contentInline">
      <div>
        <p>
          <span className="bold">
            {t("PURCHASES.ORDERS.SELECT_PAYMENT_METHOD")}
            {": "}
          </span>
          <span>
            {payment.paymentMethod
              ? t(`PAYMENT_METHOD.${payment.paymentMethod}`)
              : ""}
          </span>
        </p>
        <p>
          <span className="bold">
            {t("PAYMENT_PROVIDER")}
            {": "}
          </span>
          <span>{payment?.paymentProvider}</span>
        </p>
        <p>
          <span className="bold">
            {t("AMOUNT")}
            {": "}
          </span>
          <span>{formatCurrency(payment.amount ?? 0)}</span>
        </p>
        {!!payment?.voucher && (
          <>
            <p className="bold">{t("VOUCHER")}</p>
            <div className={styles.file}>
              <PreviewFile file={{ src: payment?.voucher }} showName />
            </div>
          </>
        )}
      </div>
      <div className="flexGap center">
        <SalePaymentsForm
          paymentMethods={paymentMethods}
          payments={payments}
          total={total ?? 0}
          onDone={onEdit}
          payment={payment}
          coins={coins}
        />
        <div className="pointer circleButton">
          <MdOutlineDelete onClick={() => onDelete(payment)} />
        </div>
      </div>
    </Card>
  );
};
