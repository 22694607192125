import {
  TProduct,
  TPurchase,
  getInvoicesByIds,
  getProductByIds,
  getPurchaseById,
} from "@/http";
import styles from "./invoiceShow.module.scss";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Invoice from "./components/Invoice";
import { useAuth } from "@/store/auth";
import { TInvoice } from "./invoiceData";
import { useEffect, useState } from "react";
import { Supplier } from "@/models/supplier";
import { TSupplierOrderDetails } from "@/models/order-details";
import useSWR from "swr";
import { SwitchSupplierFlag } from "./InvoiceShow";
import { Spinner } from "../shared/Spinner/Spinner";
import { biuldSupplierInfo } from "./utils";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import invoice_es from "../../i18n/es/invoice.json";
import invoice_en from "../../i18n/en/invoice.json";
import { Link } from "react-router-dom";
import { isCO, isUSA } from "@/constants/core";

export type Props = {
  order: TSupplierOrderDetails;
};
export const InvoiceShowOrder = ({ order }: Props) => {
  const { user } = useAuth();
  const [t] = useTranslation("invoice");
  const [products, setProducts] = useState<TProduct[]>();
  const [isLoading, setLoading] = useState(false);
  const [purchase, setPurchase] = useState<TPurchase>();
  const productsIds = order.products?.map(p => p.id);
  const lang = user.countryCode.toUpperCase() === "US" ? "en" : "es";

  useEffect(() => {
    if (productsIds && productsIds.length > 0) {
      setLoading(true);
      getProductByIds(productsIds).then(data => {
        setProducts(data);
        if (order.purchaseId) {
          getPurchaseById(order.purchaseId)
            .then(setPurchase)
            .finally(() => setLoading(false));
        }
      });
    }
  }, []);

  const invoiceType = order.status == "QUOTED" ? "QUOTATION" : "REMISSION";

  if (!Object.keys(user.address ?? {}).length) {
    return (
      <div className="warningContainer textCenter flexColumn">
        <span>{t("FUNCTION_INVOICE_DISABLED")}</span>
        <Link to="/account" className="textUnderline bold">
          {t("GO_TO_PROFILE")}
        </Link>
      </div>
    );
  }

  return (
    <>
      <div className={styles.invoiceWrapper}>
        <div className={"contentInline pw1 ph1"}>
          <div>
            <SwitchSupplierFlag
              setLoading={setLoading}
              isLoading={isLoading}
              type="showPricingInfoOnInvoice"
            />

            <SwitchSupplierFlag
              setLoading={setLoading}
              isLoading={isLoading}
              type="showClientInfoOnInvoice"
            />
            <div className="spacer" />
          </div>
          {!isLoading && (
            <ViewInvoice
              downloadButton
              invoiceType={invoiceType}
              order={order}
              products={products ?? []}
              purchase={purchase ?? ({} as TPurchase)}
              lang={lang}
            />
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <ViewInvoice
            invoiceType={invoiceType}
            order={order}
            products={products ?? []}
            purchase={purchase ?? ({} as TPurchase)}
            lang={lang}
          />
        )}
      </div>
    </>
  );
};

type TInvoiceData = {
  invoiceType: string;
  order: TSupplierOrderDetails;
  products: TProduct[];
  purchase: TPurchase;
  downloadButton?: boolean;
  lang: "es" | "en";
};
const ViewInvoice = ({
  invoiceType,
  order,
  products,
  purchase,
  downloadButton = false,
  lang,
}: TInvoiceData) => {
  const { user } = useAuth();
  const [t, setT] = useState(() => (key: string) => key);
  const [globalT] = useTranslation("global");
  const isSupplierSale = purchase?.supplierId === user.id;
  const showPrices = Boolean(user.showPricingInfoOnInvoice);
  const showClientInfo = Boolean(
    isSupplierSale && user.showClientInfoOnInvoice
  );

  let supplier: Partial<Supplier> = user;
  supplier = biuldSupplierInfo(isSupplierSale, supplier);

  const invoiceIds = [...new Set(order.products.map(p => p.invoiceId))];
  const invoiceId = invoiceIds.length == 1 ? invoiceIds[0] : undefined;
  const { data } = useSWR([[invoiceId], "invoiceByIds"], getInvoicesByIds);
  const invoiceName = data?.find(invoice => invoice.id === invoiceId)?.name;

  const invoiceData: TInvoice = {
    totalTaxes: order.totalTaxes ?? 0,
    invoiceName,
    id: order.id,
    status: order.status,
    expiresAt: undefined,
    showPrices,
    showClientInfo,
    supplier: supplier,
    client: purchase ? purchase.client : undefined,
    billing: undefined,
    number: order.subPurchaseNumber,
    createdAt: new Date(order.createdAt),
    subTotal: order.products.reduce((d: number, p) => d + (p.subtotal || 0), 0),
    discount: order.products.reduce((d: number, p) => d + p.totalDiscount, 0),
    shipping: order.shipping,
    total: order.total,
    coins: 0,
    commercialName: order.createdByName ?? "",
    items: order.products.map(product => {
      const productData = products?.find(p => p.id === product.id);
      return {
        id: product.id,
        sku: product.supplierSku,
        quantity: product.quantity,
        name: product.name,
        brand: product.brand?.name,
        discount: Number(product.totalDiscount) ?? 0,
        total: Number(product.subTotalWithDiscount) ?? 0,
        unitPrice: product.wholesalePrice,
        subTotal: product.subtotal,
        properties: productData?.productOptions,
        image: productData?.images?.map(i => i.src)?.[0],
        upc: productData?.barCode != "0" ? productData?.barCode : undefined,
        unitType: product?.unitType,
        customAttributes: product?.customAttributes ?? [],
        tax:
          isCO || !!product?.taxPercentage
            ? (isUSA ? globalT("TAX") : "IVA") + ": " + product.taxPercentage ??
              0 + "%"
            : "",
      };
    }),
    observations: order.observations ?? "",
  };

  useEffect(() => {
    const localInstance = i18n.createInstance();
    localInstance
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            invoice: invoice_en,
          },
          es: {
            invoice: invoice_es,
          },
        },
        lng: lang,
        fallbackLng: "en",
      })
      .then(() => {
        setT(() => localInstance.getFixedT(lang, "invoice"));
      });
  }, [lang]);

  if (downloadButton) {
    return (
      <PDFDownloadLink
        document={
          <Invoice translate={t} type={invoiceType} invoice={invoiceData} />
        }
      >
        <button className="primary">{globalT("DOWNLOAD")}</button>
      </PDFDownloadLink>
    );
  }

  return (
    <PDFViewer width="100%" height="700" className="app">
      <Invoice translate={t} type={invoiceType} invoice={invoiceData} />
    </PDFViewer>
  );
};
