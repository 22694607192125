import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import { TOrderDetailsPanel } from "@/models/order-details";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { ModalComment } from "../ModalComment/ModalComment";
import { usePurchaseClient } from "@/hooks/usePurchaseProducts";
import styles from "./styles.module.scss";
import { UpdateDeliveryStatus } from "./UpdateDeliveryStatus";
import { BoxDetailField } from "./BoxDetailField";
import { CURRENT_COUNTRY, isUSA } from "@/constants/core";
import { ShippingInfo } from "./ShippingInfo";
import { UpdateProviderDeliveryStatus } from "./UpdateProviderDeliveryStatus";
import clsx from "clsx";
import { getTrackingLink } from "./utils";

export const OrderDetailsPanel = ({ orderDetail }: TOrderDetailsPanel) => {
  return (
    <div className={styles.panelContainer}>
      <OrderDetails orderDetail={orderDetail} />
    </div>
  );
};

const OrderDetails = ({ orderDetail }: TOrderDetailsPanel) => {
  const [t] = useTranslation("orders-page");
  const [globalT] = useTranslation("global");
  const purchaseClient = usePurchaseClient(orderDetail?.purchaseId ?? "");
  const isExternalProvider = !["SEERI", "SUPPLIER"].includes(
    orderDetail?.deliveryProvider ?? ""
  );

  const renderTrackingInfo = () => {
    if (
      !["REJECTED", "CANCELLED", "REMISSION_GENERATED", "PENDING"].includes(
        orderDetail?.supplierStatus || ""
      )
    ) {
      if (orderDetail?.trackingCode && orderDetail?.estimatedDeliveryDate) {
        return (
          <TrackingInfo
            trackingCode={orderDetail.trackingCode}
            link={getTrackingLink(orderDetail?.trackingCode)}
            title={t(isExternalProvider ? "REFERENCE" : "DETAILS.GUIDE_ID")}
          />
        );
      } else if (["mx", "us"].includes(CURRENT_COUNTRY)) {
        return (
          <MultiTrackingInfo
            trackingCodes={orderDetail?.trackingCodes || []}
            title={t(isExternalProvider ? "REFERENCE" : "DETAILS.GUIDE_ID")}
          />
        );
      } else if (orderDetail?.trackingCode) {
        return (
          <>
            <span className={styles.title}>{t("DETAILS.GUIDE_ID")}</span>
            {orderDetail?.trackingFile ? (
              <a
                href={orderDetail?.trackingFile}
                target="_blank"
                rel="noreferrer"
                className={styles.description}
              >
                {orderDetail?.trackingCode}
              </a>
            ) : orderDetail?.supplierStatus === "APPROVED" ? (
              <span className={styles.description}>
                {t("DETAILS.GENERATING_GUIDE")}
              </span>
            ) : (
              <span className={styles.description}>
                {orderDetail?.trackingCode}
              </span>
            )}
          </>
        );
      }
    } else if (orderDetail?.reasonCancellation) {
      return (
        <CancelledInfo
          reason={orderDetail.reasonCancellation}
          message={orderDetail.messageCancellation}
          title={t("DETAILS.REJECT_REASON")}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className={styles.item}>
        <span className={styles.title}>{t("DETAILS.STATUS")}</span>
        <span
          className={clsx(styles.description, {
            [styles.red]: ["pending", "rejected", "cancelled"].includes(
              orderDetail?.supplierStatus?.toLowerCase() || ""
            ),
            [styles.green]: ["approved", "in_process"].includes(
              orderDetail?.supplierStatus?.toLowerCase() || ""
            ),
            [styles.purple]:
              orderDetail?.supplierStatus?.toLowerCase() ===
              "remission_generated",
          })}
        >
          {orderDetail?.supplierStatus
            ? t(`STATUS.${orderDetail.supplierStatus}`)
            : "-"}
        </span>
      </div>
      {orderDetail?.deliveryProvider === "SUPPLIER" && <UpdateDeliveryStatus />}
      {orderDetail?.deliveryProvider &&
        !["SUPPLIER", "SEERI"].includes(orderDetail?.deliveryProvider) && (
          <UpdateProviderDeliveryStatus />
        )}
      <div className={styles.item}>
        <span className={styles.title}>{t("ORDERS.CREATED_AT")}</span>
        <span className={styles.description}>
          {orderDetail?.createdAt
            ? format(
                new Date(orderDetail.createdAt),
                isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
              )
            : "-"}
        </span>
      </div>
      {orderDetail?.paymentMethod && (
        <div className={styles.item}>
          <span className={styles.title}>{t("PAYMENT_METHOD")}</span>
          <span className={styles.description}>
            {globalT(`PAYMENT_METHOD.${orderDetail?.paymentMethod}`) ?? "-"}
          </span>
        </div>
      )}

      <ShippingInfo purchaseId={orderDetail?.purchaseId ?? ""} />

      {orderDetail?.approvedAt && (
        <div className={styles.item}>
          <span className={styles.title}>{t("ORDERS.APPROVED_AT")}</span>
          <span className={styles.description}>
            {orderDetail?.approvedAt
              ? format(
                  new Date(orderDetail.approvedAt),
                  isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
                )
              : "-"}
          </span>
        </div>
      )}
      <div className={styles.item}>{renderTrackingInfo()}</div>
      <div className={styles.item}>
        <span className={styles.title}>{t("DETAILS.CITY")}</span>
        <span className={styles.description}>
          {purchaseClient?.location?.city || "-"}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>{t("DETAILS.PRODUCT_QUANTITY")}</span>
        <span className={styles.description}>
          {orderDetail?.products?.reduce(
            (p: number, c: any) => p + c?.quantity ?? 0,
            0
          ) || 0}
        </span>
      </div>
      {!["PENDING", "CANCELLED", "REJECTED"].includes(
        orderDetail?.status || ""
      ) && (
        <>
          {orderDetail?.amountBoxes && (
            <div className={styles.item}>
              <span className={styles.title}>{t("DETAILS.AMOUNT_BOXES")}</span>
              <span className={styles.description}>
                {orderDetail?.amountBoxes || 0}
              </span>
            </div>
          )}
          {orderDetail?.weight && (
            <div className={styles.item}>
              <span className={styles.title}>{t("WEIGHT_BOX")}</span>
              <span className={styles.description}>
                {orderDetail?.weight || 0} Kg
              </span>
            </div>
          )}
          <BoxDetailField boxId={orderDetail?.boxId ?? ""} />
        </>
      )}
    </>
  );
};

type TTrackingInfo = {
  trackingCode: string;
  link: string;
  title: string;
};
const TrackingInfo = ({ trackingCode, link, title }: TTrackingInfo) => (
  <>
    <span className={styles.title}>{title}</span>
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={styles.description}
    >
      {trackingCode}
    </a>
  </>
);

type TMultiTrackingInfo = {
  trackingCodes: { trackingCode: string; trackingLink: string }[];
  title: string;
};
const MultiTrackingInfo = ({ trackingCodes, title }: TMultiTrackingInfo) => {
  const [t] = useTranslation("orders-page");

  return (
    <>
      <span className={styles.title}>{title}</span>
      {trackingCodes.length > 0 ? (
        <>
          {trackingCodes.map((tc, index) => (
            <div key={index} className="flexGap">
              <span className="bold">
                {t("BOX")} {index + 1}:{" "}
              </span>
              <a
                href={tc.trackingLink}
                target="_blank"
                rel="noreferrer"
                className={styles.description}
              >
                {tc.trackingCode}
              </a>
            </div>
          ))}
        </>
      ) : (
        <span className={styles.description}>
          {t("DETAILS.GENERATING_GUIDE")}
        </span>
      )}
    </>
  );
};

type TCancelledInfo = {
  reason: string;
  message?: string;
  title: string;
};
const CancelledInfo = ({ reason, message, title }: TCancelledInfo) => (
  <>
    <span className={styles.title}>{title}</span>
    <span className={styles.description}>
      {reason}
      {message && <ModalComment comment={message} />}
    </span>
  </>
);
