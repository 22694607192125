import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Supplier } from "@/models/supplier";

type Props = {
  supplier: Partial<Supplier>;
  forPOS?: boolean;
};
const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
  billTo: {
    marginTop: 0,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  logo: {
    width: "50px",
    marginLeft: "0",
  },
  logoContainer: {
    marginRight: "20px",
    width: "54px",
  },
  supplierInfoContainer: {
    width: "100%",
  },
});

const stylesPOS = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  billTo: {
    marginTop: 0,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  logo: {
    width: "50px",
    marginLeft: "0",
  },
  logoContainer: {
    marginRight: "20px",
    width: "54px",
  },
  supplierInfoContainer: {
    width: "100%",
  },
});

export const SupplierInfo = ({ supplier, forPOS }: Props) => {
  const mainAddress = supplier?.address;

  return (
    <>
      <View style={forPOS ? stylesPOS.headerContainer : styles.headerContainer}>
        {supplier.logo && (
          <div style={styles.logoContainer}>
            <Image style={styles.logo} src={supplier.logo} />
          </div>
        )}
        <div style={styles.supplierInfoContainer}>
          {supplier?.name && <Text>{supplier.name}</Text>}
          {supplier?.companyId && <Text>{supplier.companyId}</Text>}
          {(mainAddress?.addressLine1 || mainAddress?.addressLine2) && (
            <Text>{`${mainAddress?.addressLine1 || ""} ${
              mainAddress?.addressLine2 || ""
            }`}</Text>
          )}
          {(mainAddress?.city ||
            mainAddress?.state ||
            mainAddress?.country) && (
            <Text>{`${mainAddress?.city ? mainAddress?.city + ", " : ""}${
              mainAddress?.state ? mainAddress?.city + ", " : ""
            }${mainAddress?.country || ""}`}</Text>
          )}
          {mainAddress?.contactName && <Text>{mainAddress?.contactName}</Text>}
          {mainAddress?.phone && <Text>{mainAddress?.phone}</Text>}
          {mainAddress?.email && <Text>{mainAddress?.email}</Text>}
        </div>
      </View>
    </>
  );
};

export default SupplierInfo;
