export const getTimeFromIso = (date: string) => {
  const d = new Date(date);
  const h = addZero(d.getHours());
  const m = addZero(d.getMinutes());
  return h + ":" + m;
};

function addZero(i: any) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const validateTimezoneDate = (date: string, includeFinal?: boolean) => {
  if (!date.endsWith("Z") && includeFinal) {
    date = date + "Z";
  }
  if (date.endsWith(".Z")) {
    return date.replace(".Z", "Z");
  }

  if (!date.endsWith("Z")) {
    date += "Z";

    if (date.includes("+")) {
      date = date.split("+")[0];
    }
  }

  return date;
};
