import { get } from "lodash-es";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { ReactNode } from "react";

type TRadioGroup = {
  name: string;
  options: { label: string | ReactNode; value: string; disabled?: boolean }[];
  required?: boolean;
  label?: string;
  labelBold?: boolean;
  color?: "purple" | "default";
  inline?: boolean;
  disabled?: boolean;
};

export const RadioGroupV2 = ({
  name,
  options,
  required = false,
  label,
  labelBold,
  color,
  inline,
}: TRadioGroup) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [t] = useTranslation("global");
  const typeError = get(errors, `${name}.type`);

  return (
    <div className={styles.radioGroupContainer}>
      {label && (
        <h6
          className={`${styles.labelGroup} ${
            typeError ? styles.labelRequired : ""
          } ${labelBold ? styles.boldLabel : ""}`}
        >
          {label}
        </h6>
      )}
      <div className={inline ? styles.inlineRadioContainer : ""}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${styles.radioContainer} ${
              typeError ? styles.required : ""
            }`}
          >
            <label
              htmlFor={`${name}-${option.value}`}
              className={styles.radioLabel}
            >
              <input
                type="radio"
                id={`${name}-${option.value}`}
                disabled={option?.disabled}
                value={option.value}
                {...register(name, {
                  required: required ? t("LOGIN.FIELD_REQUIRED") : false,
                })}
                className={`${styles.radioInput} ${
                  color === "purple" ? styles.purple : ""
                }`}
              />
              <span
                className={`${styles.customRadio} ${
                  color === "purple" ? styles.purple : ""
                }`}
              />
              <span
                className={`${styles.customText} ${
                  option?.disabled ? styles.disabledText : ""
                }`}
              >
                {option.label}
              </span>
            </label>
          </div>
        ))}
      </div>
      {typeof typeError === "string" && typeError === "required" && (
        <span className={styles.errorsLabel}>{t("LOGIN.FIELD_REQUIRED")}</span>
      )}
    </div>
  );
};
