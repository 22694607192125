import styles from "./Images.module.scss";
import { PreviewFile } from "./ProductImage";

type TImagesList = {
  images: any[];
  setImages?: (images: any[]) => void;
  minImages?: number;
  minified?: boolean;
  showName?: boolean;
};
export const ImagesList = ({
  images,
  setImages,
  minImages,
  minified,
  showName = false,
}: TImagesList) => {
  const removeImage = (e: any, value: string) => {
    e.preventDefault();

    if (setImages) {
      setImages(
        images.filter(image => {
          return image.name ? image.name !== value : image.src !== value;
        })
      );
    }
  };

  const additionalEmptyImagesCount = minImages
    ? Math.max(0, minImages - images.length)
    : 0;
  const additionalEmptyImages = Array.from(
    { length: additionalEmptyImagesCount },
    () => ({})
  );

  return (
    <div className={minified ? styles.imagesListMinified : styles.imageList}>
      {images?.map((image, idx) => {
        return (
          <PreviewFile
            file={image}
            removeFile={setImages ? removeImage : undefined}
            key={`${image.name}-${idx}-image`}
            showName={showName}
          />
        );
      })}
      {additionalEmptyImages?.map((_, idx) => (
        <div key={idx} className={styles.emptyImage} />
      ))}
    </div>
  );
};
